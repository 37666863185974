import type { ReactElement, MouseEvent } from 'react';
import React, { useCallback, useMemo, Fragment } from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';

import type { FactoryListWhereIsItMade as FactoryListSchema } from '../../../amplienceTypes/schemas/imported/product-sustainability-traceability-schema';
import type { Traceability, Factory } from '../../../utilities/graphql/codegen';

import { Dialog } from '../../shared/core/dialog/Dialog';
import type { WhereIsItMadeProps } from '../whereIsItMade/WhereIsItMade';

import { S } from './styles';
import { useHashChanged } from '../../../utilities/hooks';
import type { ProductInfo } from '../../../amplienceTypes/schemas/imported/product-info-schema';

import PinIcon from '../../shared/core/icons/PinIcon';
import SustainabilityTraceabilityIcon from '../../shared/core/icons/SustainabilityTraceability';

const WhereIsItMade = dynamic<WhereIsItMadeProps>(
  () => import('../whereIsItMade/WhereIsItMade').then(mod => mod.WhereIsItMade),
  { ssr: false }
);

interface FactoryListProps {
  schemaData?: FactoryListSchema;
}

type ObjectEntries<T> = [keyof T, T[keyof T]][];

export const FactoryList = ({ schemaData }: FactoryListProps): ReactElement => {
  const {
    product: { traceability },
  } = usePDPContext();
  const { t } = useTranslation('pdp', { keyPrefix: 'productSustainabilityTraceability' });

  const labels = schemaData?.labels;

  const {
    employeesTitleV2,
    percentageManTitleV2,
    percentageWomanTitleV2,
    productCategoriesTitleV2,
    factoryInformationTitleV2,
    sustainabilityLink,
    sustainabilityTitleV2,
  }: ProductInfo = schemaData?.whereIsItMadeContent as ProductInfo;

  const { hashMatched, setHashMatched } = useHashChanged(['#csr-map']);

  const handleOpen = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setHashMatched(true);
    },
    [setHashMatched]
  );

  const handleClose = useCallback(() => setHashMatched(false), [setHashMatched]);

  const factories = useMemo(
    () =>
      labels &&
      traceability &&
      (Object.entries(labels) as ObjectEntries<typeof labels>).map(([key, value]) => {
        const factory = traceability[key as keyof Traceability] as Factory;
        const factoryLabel = value as string;

        if (factory && factoryLabel) {
          if ((key as keyof Traceability) === 'garmentManufacturing') {
            return (
              <Fragment key={key}>
                <S.FactoryList.Process>{factoryLabel}</S.FactoryList.Process>
                <S.FactoryList.Factory>
                  <a href={sustainabilityLink} onClick={handleOpen}>
                    <S.FactoryList.IconWrapper>
                      <PinIcon />
                    </S.FactoryList.IconWrapper>
                    {factory.name}, {factory.country}
                  </a>
                </S.FactoryList.Factory>
              </Fragment>
            );
          }

          return (
            <Fragment key={key}>
              <S.FactoryList.Process>{factoryLabel}</S.FactoryList.Process>
              <S.FactoryList.Factory>
                {factory.name}, {factory.country}
              </S.FactoryList.Factory>
            </Fragment>
          );
        }

        return null;
      }),
    [labels, traceability, handleOpen, sustainabilityLink]
  );

  const hasFactories = useMemo(
    () => (factories?.filter(factory => factory !== null).length ?? 0) > 0,
    [factories]
  );

  return (
    <S.Container>
      <S.Header variant="h5" component="h6">
        <SustainabilityTraceabilityIcon type="FACTORY_LIST" />
        {schemaData?.title ?? t('title')}
      </S.Header>
      <S.FactoryList.Layout>
        {hasFactories ? (
          <S.FactoryList.FactoryList>{factories}</S.FactoryList.FactoryList>
        ) : (
          <a href={sustainabilityLink} onClick={handleOpen}>
            <S.FactoryList.IconWrapper>
              <PinIcon />
            </S.FactoryList.IconWrapper>
            {sustainabilityTitleV2 ?? t('title')}
          </a>
        )}
      </S.FactoryList.Layout>
      {hashMatched && (
        <Dialog ariaLabel="where is it made" onClose={handleClose}>
          <WhereIsItMade
            factoryInfoTitles={{
              employeesTitle: employeesTitleV2 || t('employeesTitle'),
              percentageManTitle: percentageManTitleV2 || t('percentageManTitle'),
              percentageWomanTitle: percentageWomanTitleV2 || t('percentageWomanTitle'),
              productCategoriesTitle: productCategoriesTitleV2 || t('productCategoriesTitle'),
              factoryInformationTitle: factoryInformationTitleV2 || t('factoryInformationTitle'),
            }}
          />
        </Dialog>
      )}
    </S.Container>
  );
};
