import styled from 'styled-components';
import { Typography } from '../../shared/core/typography/Typography';
import { colors } from '../../shared/core/styles';

export const S = {
  AccordionTitle: styled(Typography)`
    padding-bottom: 9px;
  `,

  Container: styled.div`
    padding: 12px 0 16px;
    border-bottom: 1px solid ${colors.COLOR_GREY_200};

    &:last-child {
      border-bottom: none;
    }
  `,

  Header: styled(Typography)`
    display: flex;
    align-items: center;
    font-size: 14px;
    text-transform: capitalize;
    gap: 8px;

    svg {
      flex: none;
    }
  `,
  Layout: styled.div<{ $size?: 'default' | 'small' }>`
    padding: 8px 32px 0 40px;
    font-size: ${({ $size }) => ($size === 'small' ? '12px' : '14px')};
    line-height: ${({ $size }) => ($size === 'small' ? '18px' : '20px')};

    a {
      text-decoration: underline;
      color: ${colors.DARKNIGHT_RIDER_GREY};

      &:hover,
      &:focus {
        color: #177c35;
      }
    }
  `,
  PreferredMaterials: {
    Layout: styled.div`
      padding: 8px 32px 0 40px;
    `,
    Link: styled.a`
      font-size: 14px;
      text-decoration: underline;
      color: ${colors.BLUE_CHARCOAL};

      &:hover,
      &:focus {
        color: #177c35;
      }
    `,
  },

  Fibers: {
    FiberList: styled.ul`
      margin: 0;
      padding: 0;
      list-style: none;
    `,
    Fiber: styled.li`
      padding-bottom: 8px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;

      a {
        text-decoration: underline;
        color: ${colors.DARKNIGHT_RIDER_GREY};

        &:hover,
        &:focus {
          color: #177c35;
        }
      }
    `,
  },

  FactoryList: {
    Layout: styled.div`
      padding: 8px 20px 0 40px;

      a {
        text-decoration: underline;
        color: ${colors.DARKNIGHT_RIDER_GREY};

        &:hover,
        &:focus {
          color: #177c35;

          svg path {
            fill: #177c35 !important;
          }
        }
      }
    `,
    FactoryList: styled.dl`
      margin: 0;
    `,
    Process: styled.dt`
      margin-bottom: 4px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 700;
    `,
    Factory: styled.dd`
      margin: 0;
      margin-bottom: 16px;
      font-size: 12px;
      line-height: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    `,
    IconWrapper: styled.i`
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-top: 2px;
      vertical-align: top;
    `,
  },
};
