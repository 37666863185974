/* eslint-disable react/no-danger */
import { useMemo } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { SizeGuideUnit } from '../../../utilities/graphql/codegen';
import { colors } from '../../shared/core/styles';

const S = {
  Wrapper: styled.div`
    p {
      margin-bottom: 8px;
    }
  `,

  List: styled.ul`
    padding: 0;
    margin: 0 0 13px 16px;
    line-height: 20px;

    a {
      color: ${colors.NERO_BLACK};
      text-decoration: underline;

      &:hover,
      &:focus {
        color: ${colors.NERO_GREY};
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  `,
};

interface ProductSizeFitProps {
  title: string;
}

export const ProductSizeFit = ({ title }: ProductSizeFitProps): ReactElement => {
  const {
    configuration: { sizeGuideUnit },
  } = useStaticContext();
  const {
    product: { mannequin, dimensionsBullets },
  } = usePDPContext();

  const sizeGuideMessage = useMemo(
    () =>
      (sizeGuideUnit === SizeGuideUnit.Inch
        ? mannequin?.imperialMessage
        : mannequin?.metricMessage
      )?.replace(/(\d+(\.\d+)?m|\d+'\d+("|')'?)|(\d+\/\d+)/gi, '<b>$&</b>'),
    [sizeGuideUnit, mannequin]
  );

  return (
    <S.Wrapper>
      <h2 data-accordion-title>{title}</h2>
      {sizeGuideMessage && <p dangerouslySetInnerHTML={{ __html: sizeGuideMessage }} />}
      {Array.isArray(dimensionsBullets) && Boolean(dimensionsBullets.length) && (
        <S.List>
          {dimensionsBullets.map((e, i) => (
            <li key={i} dangerouslySetInnerHTML={{ __html: e || '' }} />
          ))}
        </S.List>
      )}
    </S.Wrapper>
  );
};
